import {
  Badge,
  Button,
  Empty,
  Pagination,
  Space,
  Table,
  Tag,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  FilterOutlined,
  ContainerOutlined,
  UndoOutlined,
  CheckCircleFilled,
} from "@ant-design/icons";

import { useNavigate } from "react-router-dom";
import DashboardBreadcrumb from "../../components/layout/DashboardBreadcrumb";
import OrdersFilterModal from "../../components/modals/filters/OrdersFilterModal";
import GetOrdersTableApi from "../../api/Orders/GetOrdersTableApi";
import { GetDate, GetTime } from "../../helpers/Date&Time";
import MainLoader from "../../Loader/MainLoader";
import GetFilteredOrdersTableApi from "../../api/Orders/GetFilteredOrdersTableApi";
import ConfirmationModal from "../../components/modals/ConfirmationModal";
import ArchiveOrderApi from "../../api/Orders/ArchiveOrderApi";
import {
  openNotificationError,
  openNotificationSuccess,
} from "../../helpers/Notifications";
import { STATUS } from "../../helpers/constants";
import { currencyFormatter } from "../../helpers/currencyFormat";
import DataCard from "../../components/widgets/DataCard";
import { useSelector } from "react-redux";

function Orders() {
  let navigate = useNavigate();
  const [filterModal, setFilterModal] = useState(false);
  const [data, setData] = useState();
  const [canShowTable, setCanShowTable] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [canStart, setCanStart] = useState(false);
  const [page, setPage] = useState(1);
  const [priceRange, setPriceRange] = useState([]);
  const [filterStatus, setFilterStatus] = useState(false);
  const [dateRange, setDateRange] = useState([]);
  const [archiveModal, setArchiveModal] = useState(false);
  const [orderId, setOrderId] = useState();
  const businessState = useSelector((state) => state.businessState.value);
  const proHeight = document.body.clientHeight;
  const buttons = (record) => (
    <Space size="middle">
      <Tooltip title={"Archive"}>
        <div
          onClick={(event) => {
            event.stopPropagation();
            setArchiveModal(true);
            setOrderId(record.id);
          }}
          className="text-blue-500 hover:text-blue-600 cursor-pointer text-base"
        >
          <ContainerOutlined />
        </div>
      </Tooltip>
    </Space>
  );

  useEffect(() => {
    getOrdersTable(1);
  }, []);

  async function getOrdersTable(num) {
    setTableLoading(true);
    const apiResponse = await GetOrdersTableApi(num);
    if (apiResponse.success) {
      if (apiResponse.data?.orders?.length) {
        setCanShowTable(true);
      }
      setData(apiResponse.data);
      setCanStart(true);
      setTableLoading(false);
      setPage(num);
    }
  }

  async function getFilteredOrders(values, num) {
    setTableLoading(true);
    const apiResponse = await GetFilteredOrdersTableApi(
      values.tableNo || "",
      dateRange[0] || "",
      dateRange[1] || "",
      priceRange[0] || "",
      priceRange[1] || "",
      values.status || "",
      num
    );
    if (apiResponse.success) {
      setData(apiResponse.data);
      setTableLoading(false);
      setFilterStatus(true);
      setPage(num);
    }
  }

  const handleData = (newData) => {
    setPriceRange(newData);
  };

  const handleDate = (newData) => {
    setDateRange(newData);
  };

  async function archiveOrder() {
    const apiResponse = await ArchiveOrderApi(orderId);
    if (apiResponse.error) {
      openNotificationError("bottomRight", apiResponse.message);
    } else if (apiResponse.success) {
      setData((prevData) => ({
        ...prevData,
        orders: prevData.orders.filter((order) => order.id !== orderId),
      }));
      openNotificationSuccess("bottomRight", "Archived order");
      if (data?.orders?.length < 10 && page > 1) {
        changeTablePage(page - 1);
      } else {
        changeTablePage(page);
      }
    } else {
      openNotificationError(
        "bottomRight",
        "Couldn't archive order. Try again later."
      );
    }
  }

  function changeTablePage(num) {
    getOrdersTable(num);
  }

  const orderData = data?.orders?.map((item) => ({
    ...item,
    subTotal: currencyFormatter(item.subTotal, businessState?.currency?.symbol),
  }));

  const columns = [
    {
      title: "Ordered Date",
      dataIndex: "orderedOn",
      key: "orderedOn",
      render: (text, record) => <div>{GetDate(record.orderedOn)}</div>,
    },
    {
      title: "Ordered Time",
      dataIndex: "orderedOn",
      key: "orderedOn",
      render: (text, record) => <div>{GetTime(record.orderedOn)}</div>,
    },
    {
      title: "Table",
      dataIndex: "tableNo",
      key: "tableNo",
      render: (text, record) => <div>{record.tableDetails?.name}</div>,
    },

    {
      title: "No of items",
      dataIndex: "noOfItems",
      key: "noOfItems",
    },
    {
      title: "Total price",
      dataIndex: "subTotal",
      key: "subTotal",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (
        <Space size="middle">
          {text === STATUS.Closed ? (
            <Tag color="blue">Closed</Tag>
          ) : text === STATUS.Cancelled ? (
            <Tag color="red">Cancelled</Tag>
          ) : (
            ""
          )}
        </Space>
      ),
    },

    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      fixed: "right",
      width: 140,
      ellipsis: true,
      render: (text, record) => (
        <Space size="middle">
          <Tooltip title={"Archive"}>
            <Button
              danger
              ghost
              size="small"
              onClick={(event) => {
                event.stopPropagation();
                setArchiveModal(true);
                setOrderId(record.id);
              }}
              icon={<ContainerOutlined />}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  function resetFilter() {
    getOrdersTable(1);
    setFilterStatus(false);
    setPriceRange(["", ""]);
    setDateRange(["", ""]);
  }

  const right = (
    <>
      {canShowTable && (
        <>
          <Tooltip title={"Reset"}>
            <Button
              disabled={!filterStatus}
              onClick={() => resetFilter()}
              className="mr-3"
            >
              <UndoOutlined />
            </Button>
          </Tooltip>
          <Tooltip title={"Filter"}>
            <Badge
              count={
                filterStatus ? (
                  <CheckCircleFilled style={{ color: "#f5222d" }} />
                ) : (
                  0
                )
              }
              offset={[-14, 2]}
            >
              <Button className="mr-3" onClick={() => setFilterModal(true)}>
                <FilterOutlined />
              </Button>
            </Badge>
          </Tooltip>
        </>
      )}
    </>
  );

  const left = (
    <>
      <div className="pri-font text-lg hidden md:block">Orders</div>
    </>
  );

  return (
    <>
      <div className="my-4">
        <DashboardBreadcrumb left={left} right={right} />
        <div
          style={{
            height: proHeight - 700,
          }}
        >
          {canStart ? (
            canShowTable ? (
              <>
                <Table
                  className="hidden sm:block"
                  rowClassName=" cursor-pointer"
                  dataSource={orderData}
                  columns={columns}
                  loading={tableLoading}
                  pagination={{ position: ["none"] }}
                  onRow={(record) => {
                    return {
                      onClick: () => {
                        navigate(`/orders/${record.id}`);
                      },
                    };
                  }}
                />
                <div className={"sm:hidden grid grid-cols-1 gap-2"}>
                  {data?.orders?.map((order) => (
                    <DataCard
                      key={order?.id}
                      category={order?.category?.name}
                      title={order?.tableDetails?.name}
                      actions={buttons(order)}
                      price={order?.subTotal}
                      status={order?.status}
                      date={GetDate(order?.orderedOn)}
                      time={order?.orderedOn}
                    />
                  ))}
                </div>

                <div className="text-end mt-4">
                  {data?.totalCount > 10 && (
                    <Pagination
                      onChange={(num) => {
                        getOrdersTable(num);
                        setPage(num);
                      }}
                      current={page}
                      total={data?.totalCount || 0}
                      pageSize={10}
                    />
                  )}
                </div>
              </>
            ) : (
              <Table
                columns={columns}
                locale={{
                  emptyText: (
                    <Empty
                      imageStyle={{
                        height: 50,
                      }}
                      description={<span>No Order Available</span>}
                    ></Empty>
                  ),
                }}
              />
            )
          ) : (
            <MainLoader />
          )}
        </div>
        <OrdersFilterModal
          visible={filterModal}
          handleCancel={() => setFilterModal(false)}
          priceRange={(value) => handleData(value)}
          dateRange={(value) => handleDate(value)}
          handleOk={(values) => getFilteredOrders(values, 1)}
        />
        <ConfirmationModal
          visible={archiveModal}
          handleCancel={() => {
            setArchiveModal(false);
          }}
          handleOk={() => {
            archiveOrder();
            setArchiveModal(false);
          }}
          text="Are you sure want to archive this order?"
          title="Archive"
        />
      </div>
    </>
  );
}
export default Orders;
