import {
  CheckCircleFilled,
  DeleteOutlined,
  EditOutlined,
  FilterOutlined,
  PlusCircleOutlined,
  PlusOutlined,
  SwapOutlined,
  UndoOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import {
  Badge,
  Button,
  Pagination,
  Popover,
  Space,
  Table,
  Tag,
  theme,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import DeleteTableApi from "../../api/Tables/DeleteTableApi";
import DisableTableApi from "../../api/Tables/DisableTableApi";
import GetFilteredTablesTableApi from "../../api/Tables/GetFilteredTablesTableApi";
import GetTablesTableApi from "../../api/Tables/GetTablesTableApi";
import DashboardBreadcrumb from "../../components/layout/DashboardBreadcrumb";
import AddTableModal from "../../components/modals/add/AddTableModal";
import ChangeQRModal from "../../components/modals/ChangeQRModal";
import ConfirmationModal from "../../components/modals/ConfirmationModal";
import EditTableModal from "../../components/modals/edit/EditTableModal";
import TablesFilterModal from "../../components/modals/filters/TablesFilterModal";
import {
  openNotificationError,
  openNotificationSuccess,
} from "../../helpers/Notifications";
import MainLoader from "../../Loader/MainLoader";
import RemoveQrTableApi from "../../api/Qr/RemoveQrTableApi";
import DataCard from "../../components/widgets/DataCard";
import { STATUS_VALUE } from "../../helpers/status";
import { STATUS } from "../../helpers/constants";

const { useToken } = theme;

function Tables() {
  const { token } = useToken();
  const [addModal, setAddModal] = useState(false);
  const [changeQRModal, setChangeQRModal] = useState(false);
  const [canShowTable, setCanShowTable] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [data, setData] = useState();
  const [canStart, setCanStart] = useState(false);
  const [page, setPage] = useState(1);
  const [editModal, setEditModal] = useState(false);
  const [tableData, setTableData] = useState();
  const [filterModal, setFilterModal] = useState(false);
  const [filterStatus, setFilterStatus] = useState(false);
  const [filterBy, setFilterBy] = useState();
  const [disableModal, setDisableModal] = useState(false);
  const [disableTableId, setDisableTableId] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteTableId, setDeleteTableId] = useState();
  const [deleteQrModal, setDeleteQrModal] = useState(false);
  const [deleteQr, setDeleteQr] = useState();

  useEffect(() => {
    getTables(1);
  }, []);

  const buttons = (record) => (
    <Space size="middle">
      <Tooltip title={"Edit"}>
        <div
          className="text-blue-500 hover:text-blue-600 cursor-pointer"
          onClick={() => {
            setTableData(record);
            setEditModal(true);
          }}
        >
          <EditOutlined />
        </div>
      </Tooltip>

      {record.status === "Occupied" || record.qrCodeNo ? (
        <Popover
          placement="left"
          content="Can't delete occupied or QR-assigned tables"
          trigger="hover"
        >
          <DeleteOutlined className="text-gray-500" />
        </Popover>
      ) : (
        <Tooltip title={"Delete"}>
          <div
            className="text-red-500 hover:text-red-600 cursor-pointer"
            onClick={() => {
              setDeleteModal(true);
              setDeleteTableId(record.id);
            }}
          >
            <DeleteOutlined />
          </div>
        </Tooltip>
      )}
    </Space>
  );

  async function getTables(num) {
    setTableLoading(true);
    const apiResponse = await GetTablesTableApi(num);
    if (apiResponse.success) {
      if (apiResponse.data?.tables?.length) {
        setCanShowTable(true);
      }
      setData(apiResponse.data);
      setCanStart(true);
      setTableLoading(false);
      setPage(num);
    }
  }

  async function getFilteredTables(values, num) {
    setTableLoading(true);
    const apiResponse = await GetFilteredTablesTableApi(
      values.name || "",
      values.status || "",
      num
    );
    if (apiResponse.success) {
      setData(apiResponse.data);
      setTableLoading(false);
      setFilterStatus(true);
      setFilterBy({
        name: values.name,
        status: values.status,
      });
      setPage(num);
    }
  }

  async function disableTable() {
    const apiResponse = await DisableTableApi(disableTableId);
    if (apiResponse.error) {
      openNotificationError("bottomRight", apiResponse.message);
    } else if (apiResponse.success) {
      openNotificationSuccess("bottomRight", "Table disabled");
      changeTablePage(page);
    } else {
      openNotificationError(
        "bottomRight",
        "Couldn't disable. Try again later."
      );
    }
    setDisableTableId("");
  }

  async function deleteTable() {
    const apiResponse = await DeleteTableApi(deleteTableId);
    if (apiResponse.error) {
      openNotificationError("bottomRight", apiResponse.message);
    } else if (apiResponse.success) {
      openNotificationSuccess("bottomRight", "Table deleted");
      if (data?.tables?.length.toString().endsWith("1")) {
        changeTablePage(page - 1);
      } else {
        changeTablePage(page);
      }
    } else {
      openNotificationError("bottomRight", "Couldn't delete. Try again later.");
    }
    setDeleteTableId("");
  }

  async function deleteQR() {
    const apiResponse = await RemoveQrTableApi(deleteQr);
    if (apiResponse.error) {
      openNotificationError("bottomRight", apiResponse.message);
    } else if (apiResponse.success) {
      openNotificationSuccess(
        "bottomRight",
        "QR successfully deleted from table"
      );
      if (data?.tables?.length.toString().endsWith("1")) {
        changeTablePage(page - 1);
      } else {
        changeTablePage(page);
      }
    } else {
      openNotificationError("bottomRight", "Couldn't delete. Try again later.");
    }
  }

  function changeTablePage(num) {
    if (filterStatus) {
      getFilteredTables(filterBy, num);
    } else {
      getTables(num);
    }
  }

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "No of seats",
      dataIndex: "seats",
      key: "noOfSeats",
    },
    {
      title: "QR Code",
      dataIndex: "qrCodeNo",
      key: "qrCode",
      render: (text, record) => (
        <div className="flex items-center gap-2">
          {text}
          {record.qrCodeNo ? (
            record.status === "Free" ? (
              <>
                <Tooltip title={"Change QR code"}>
                  <Button
                    type="primary"
                    ghost
                    size="small"
                    style={{ border: "none" }}
                    onClick={() => {
                      setTableData(record);
                      setChangeQRModal(true);
                    }}
                    icon={<SwapOutlined />}
                  />
                </Tooltip>
                <Tooltip title={"Delete table"}>
                  <Button
                    style={{ border: "none" }}
                    danger
                    size="small"
                    onClick={() => {
                      setDeleteQrModal(true);
                      setDeleteQr(record.qrCodeNo);
                    }}
                    icon={<DeleteOutlined />}
                  />
                </Tooltip>
              </>
            ) : (
              <>
                <Popover
                  placement="left"
                  content="Can't swap occupied tables"
                  trigger="hover"
                >
                  <Button
                    style={{ border: "none" }}
                    size="small"
                    icon={<SwapOutlined className="text-gray-500" />}
                  />
                </Popover>
                <Popover
                  placement="left"
                  content="Can't delete occupied tables"
                  trigger="hover"
                >
                  <Button
                    style={{ border: "none" }}
                    size="small"
                    icon={<DeleteOutlined className="text-gray-500" />}
                  />
                </Popover>
              </>
            )
          ) : (
            <Tooltip title={"Assign QR code"}>
              <Button
                type="primary"
                ghost
                style={{ border: "none" }}
                size="small"
                onClick={() => {
                  setTableData(record);
                  setChangeQRModal(true);
                }}
                icon={<PlusCircleOutlined />}
              />
            </Tooltip>
          )}
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (
        <Space size="middle">
          {record.status === STATUS.Free ? (
            <Tag color="blue">Free</Tag>
          ) : (
            <Tag color="orange">Occupied</Tag>
          )}
        </Space>
      ),
    },

    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      fixed: "right",
      width: 140,
      ellipsis: true,
      render: (text, record) => (
        <Space size="middle">
          <Tooltip title={"Edit"}>
            <Button
              type="primary"
              ghost
              size="small"
              onClick={() => {
                setTableData(record);
                setEditModal(true);
              }}
              icon={<EditOutlined />}
            />
          </Tooltip>

          {record.status === "Occupied" || record.qrCodeNo ? (
            <Popover
              placement="left"
              content="Can't delete occupied or QR-assigned tables"
              trigger="hover"
            >
              <Button
                style={{ borderColor: "gray" }}
                size="small"
                icon={<DeleteOutlined className="text-gray-500" />}
              />
            </Popover>
          ) : (
            <Tooltip title={"Delete"}>
              <Button
                danger
                size="small"
                onClick={() => {
                  setDeleteModal(true);
                  setDeleteTableId(record.id);
                }}
                icon={<DeleteOutlined />}
              />
            </Tooltip>
          )}
        </Space>
      ),
    },
  ];

  function showEmptyTables() {
    return (
      <div className="flex justify-center items-center w-full h-full">
        <div className="text-center">
          <div className="text-3xl text-gray-400">
            <WarningOutlined />
          </div>
          <div className="text-xl pri-font text-gray-400">
            No tables available
          </div>
          <div className="mb-3 text-gray-400">Add your first item</div>
          <Button
            type="primary"
            style={{ background: token.colorPrimary }}
            onClick={() => setAddModal(true)}
          >
            Add a table
          </Button>
        </div>
      </div>
    );
  }

  function resetFilter() {
    getTables(1);
    setFilterStatus(false);
    setFilterBy();
  }

  const right = (
    <>
      {canShowTable && (
        <>
          <Tooltip title={"Reset"}>
            <Button
              className="mr-3"
              disabled={!filterStatus}
              onClick={() => resetFilter()}
            >
              <UndoOutlined />
            </Button>
          </Tooltip>
          <Tooltip title={"Filter"}>
            <Badge
              count={
                filterStatus ? (
                  <CheckCircleFilled style={{ color: "#f5222d" }} />
                ) : (
                  0
                )
              }
              offset={[-14, 2]}
            >
              <Button
                className="mr-3"
                onClick={() => {
                  setFilterModal(true);
                }}
              >
                <FilterOutlined />
              </Button>
            </Badge>
          </Tooltip>
          <Tooltip placement="left" title={"Add Table"}>
            <Button
              style={{ background: token.colorPrimary }}
              shape="circle"
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => setAddModal(true)}
            ></Button>
          </Tooltip>
        </>
      )}
    </>
  );
  const left = (
    <>
      <div className="pri-font text-lg hidden md:block">Tables</div>
    </>
  );
  return (
    <>
      <div className="my-4">
        <DashboardBreadcrumb left={left} right={right} />
        {canStart ? (
          canShowTable ? (
            <>
              <div>
                <Table
                  className="hidden md:block"
                  dataSource={data?.tables}
                  columns={columns}
                  loading={tableLoading}
                  pagination={{ position: ["none"] }}
                />
              </div>

              <div className={"md:hidden grid grid-cols-1 gap-2"}>
                {data?.tables.map((table) => (
                  <DataCard
                    key={table?.id}
                    title={table?.name}
                    description={table?.seats}
                    status={table?.status}
                    actions={buttons(table)}
                    qrCode={table?.qrCodeNo}
                  />
                ))}
              </div>

              <div className="text-end mt-4 mb-16">
                {data?.totalCount > 10 && (
                  <Pagination
                    onChange={(num) => {
                      changeTablePage(num);
                      setPage(num);
                    }}
                    current={page}
                    total={data?.totalCount || 0}
                    pageSize={10}
                  />
                )}
              </div>
            </>
          ) : (
            showEmptyTables()
          )
        ) : (
          <MainLoader />
        )}
        <AddTableModal
          visible={addModal}
          handleCancel={() => setAddModal(false)}
          handleOk={() => {
            getTables(1);
            setAddModal(false);
          }}
        />
        <TablesFilterModal
          visible={filterModal}
          handleCancel={() => setFilterModal(false)}
          handleOk={(values) => getFilteredTables(values, 1)}
        />
        <EditTableModal
          visible={editModal}
          data={tableData}
          handleCancel={() => setEditModal(false)}
          handleOk={() => {
            getTables(1);
            setEditModal(false);
          }}
        />
        <ChangeQRModal
          visible={changeQRModal}
          data={tableData}
          handleCancel={() => setChangeQRModal(false)}
          handleOk={() => getTables(1)}
        />
        <ConfirmationModal
          visible={disableModal}
          handleCancel={() => setDisableModal(false)}
          handleOk={() => {
            disableTable();
            setDisableModal(false);
          }}
          text="Are you sure want to disable this table?"
          title="Disable table"
        />
        <ConfirmationModal
          visible={deleteModal}
          handleCancel={() => setDeleteModal(false)}
          handleOk={() => {
            deleteTable();
            setDeleteModal(false);
          }}
          text="Are you sure want to delete this table?"
          title="Delete table"
        />
        <ConfirmationModal
          visible={deleteQrModal}
          handleCancel={() => setDeleteQrModal(false)}
          handleOk={() => {
            deleteQR();
            setDeleteQrModal(false);
          }}
          text="Are you sure want to delete this qr from table?"
          title="Delete QR"
        />
      </div>
    </>
  );
}

export default Tables;
