import { Descriptions, Table } from "antd";
import React, { useEffect, useState } from "react";
import DashboardBreadcrumb from "../../components/layout/DashboardBreadcrumb";
import AddDiscountModal from "../../components/modals/add/AddDiscountModal";
import OrderedItemInfoModal from "../../components/modals/OrderedItemInfoModal";
import GetOrderItemByIdApi from "../../api/Orders/GetOrderItemByIdApi";
import { useLocation } from "react-router-dom";
import { GetTime } from "../../helpers/Date&Time";
import MainLoader from "../../Loader/MainLoader";
import ConfirmationModal from "../../components/modals/ConfirmationModal";
import ArchiveOrderApi from "../../api/Orders/ArchiveOrderApi";
import {
  openNotificationError,
  openNotificationSuccess,
} from "../../helpers/Notifications";
import { DeleteOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { currencyFormatter } from "../../helpers/currencyFormat";

function OrderedItem() {
  const [addModal, setAddModal] = useState(false);
  const [infoModal, setInfoModal] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [canStart, setCanStart] = useState(false);
  const [orderItemInfo, setOrderItemInfo] = useState();
  const [archiveModal, setArchiveModal] = useState(false);
  const curLocation = useLocation();
  const businessState = useSelector((state) => state.businessState.value);
  const path = curLocation.pathname;
  const id = path.split("/");

  useEffect(() => {
    getOrdersTable();
  }, []);

  async function getOrdersTable() {
    setLoading(true);
    const apiResponse = await GetOrderItemByIdApi(id[2]);
    if (apiResponse.success) {
      setData(apiResponse.data);
      setCanStart(true);
      setLoading(false);
    }
  }

  async function archiveOrder() {
    const apiResponse = await ArchiveOrderApi(id[2]);
    if (apiResponse.error) {
      openNotificationError("bottomRight", apiResponse.message);
    } else if (apiResponse.success) {
      openNotificationSuccess("bottomRight", "Archived order");
    } else {
      openNotificationError("bottomRight", "Couldn't delete. Try again later.");
    }
  }

  const orderItems = data?.orderItems ? data?.orderItems : [];
  const items = orderItems.map((item) => ({
    ...item,
    price: currencyFormatter(item.price, businessState?.currency?.symbol),
  }));
  const columns = [
    {
      title: "Item",
      dataIndex: "menuName",
      key: "menuName",
    },
    {
      title: "No of items",
      dataIndex: "noOfItems",
      key: "noOfItems",
    },
    {
      title: "Sub total",
      dataIndex: "price",
      key: "price",
    },
  ];

  const right = (
    <>
      <div
        className="flex justify-center p-2 cursor-pointer hover:bg-red-200  bg-red-100 hover:text-red-600 text-red-500 rounded-md"
        onClick={() => setArchiveModal(true)}
      >
        <DeleteOutlined />
      </div>
    </>
  );

  const left = <div className="pri-font text-lg">Orders / {data?.id}</div>;

  return (
    <>
      <div className="my-4">
        <DashboardBreadcrumb left={left} right={right} />
        {canStart ? (
          <>
            <div className=" mb-4">
              <div className=" grid grid-cols-2 gap-5">
                <div className="bg-white p-5 rounded-md ">
                  <Descriptions title="Order Details">
                    <Descriptions.Item
                      label="Qr Id"
                      labelStyle={{ color: "gray" }}
                    >
                      {data?.qrId}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label="Table"
                      labelStyle={{ color: "gray" }}
                    >
                      {data?.tableDetails?.name}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label="Order Id"
                      labelStyle={{ color: "gray" }}
                    >
                      {data?.id}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label="Total Items"
                      labelStyle={{ color: "gray" }}
                    >
                      {data?.noOfItems}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label="Ordered Time"
                      labelStyle={{ color: "gray" }}
                    >
                      {GetTime(data?.orderedOn)}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label="No Of Pax"
                      labelStyle={{ color: "gray" }}
                    >
                      {data?.customersCount}
                    </Descriptions.Item>
                  </Descriptions>
                </div>
                <div className="bg-white p-5 rounded-md">
                  <Descriptions title="Price Details">
                    <Descriptions.Item
                      label="Sub Total"
                      labelStyle={{ color: "gray" }}
                    >
                      {currencyFormatter(
                        data?.subTotal,
                        businessState?.currency?.symbol
                      )}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label="Tax"
                      labelStyle={{ color: "gray" }}
                    >
                      {currencyFormatter(
                        data?.tax,
                        businessState?.currency?.symbol
                      )}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label="Service Charge"
                      labelStyle={{ color: "gray" }}
                    >
                      {currencyFormatter(
                        data?.serviceCharge,
                        businessState?.currency?.symbol
                      )}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label="Discount"
                      labelStyle={{ color: "gray" }}
                    >
                      {currencyFormatter(
                        data?.discount,
                        businessState?.currency?.symbol
                      )}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label="Grand Total"
                      className=" font-semibold"
                      labelStyle={{ color: "gray" }}
                    >
                      {currencyFormatter(
                        data?.grandTotal,
                        businessState?.currency?.symbol
                      )}
                    </Descriptions.Item>
                  </Descriptions>
                </div>
              </div>
            </div>

            <Table
              rowClassName=" cursor-pointer"
              dataSource={items}
              columns={columns}
              loading={loading}
              pagination={{ position: ["none"] }}
              onRow={(record) => {
                return {
                  onClick: () => {
                    setInfoModal(true);
                    setOrderItemInfo(record);
                  },
                };
              }}
            />
          </>
        ) : (
          <MainLoader />
        )}

        <AddDiscountModal
          visible={addModal}
          handleCancel={() => setAddModal(false)}
        />
        <OrderedItemInfoModal
          visible={infoModal}
          data={orderItemInfo}
          handleCancel={() => setInfoModal(false)}
        />
        <ConfirmationModal
          visible={archiveModal}
          handleCancel={() => {
            setArchiveModal(false);
          }}
          handleOk={() => {
            archiveOrder();
            setArchiveModal(false);
          }}
          text="Are you sure want to archive this order?"
          title="Archive"
        />
      </div>
    </>
  );
}

export default OrderedItem;
