import {
  Button,
  Tooltip,
  theme,
  Table,
  Space,
  Tag,
  Dropdown,
  Pagination,
  Badge,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  FilterOutlined,
  PlusOutlined,
  ColumnHeightOutlined,
  WarningOutlined,
  IssuesCloseOutlined,
  MoreOutlined,
  UndoOutlined,
  CheckCircleFilled,
} from "@ant-design/icons";
import DashboardBreadcrumb from "../../components/layout/DashboardBreadcrumb";
import AddItemModal from "../../components/modals/add/AddItemModal";
import EditItemModal from "../../components/modals/edit/EditItemModal";
import ChangeItemStatusModal from "../../components/modals/ChangeItemStatusModal";
import ChangeItemPositionModal from "../../components/modals/ChangeItemPositionModal";
import ItemsFilterModal from "../../components/modals/filters/ItemsFilterModal";
import GetItemsTableApi from "../../api/Items/GetItemsTableApi";
import MainLoader from "../../Loader/MainLoader";
import ConfirmationModal from "../../components/modals/ConfirmationModal";
import DeleteItemApi from "../../api/Items/DeleteItemApi";
import {
  openNotificationError,
  openNotificationSuccess,
} from "../../helpers/Notifications";
import GetFilteredItemsTableApi from "../../api/Items/GetFilteredItemsTableApi";
import ItemInfoModal from "../../components/modals/ItemInfoModal";
import { STATUS } from "../../helpers/constants";
import { currencyFormatter } from "../../helpers/currencyFormat";
import { useSelector } from "react-redux";
import DataCard from "../../components/widgets/DataCard";

const { useToken } = theme;

function Items() {
  const { token } = useToken();
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [filterModal, setFilterModal] = useState(false);
  const [statusModal, setStatusModal] = useState(false);
  const [positionModal, setPositionModal] = useState(false);
  const [canShowTable, setCanShowTable] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [data, setData] = useState();
  const [canStart, setCanStart] = useState(false);
  const [page, setPage] = useState(1);
  const [changeStatus, setChangeStatus] = useState();
  const [changePosition, setChangePosition] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [filterStatus, setFilterStatus] = useState(false);
  const [filterBy, setFilterBy] = useState();
  const [priceRange, setPriceRange] = useState([]);
  const [selectItem, setSelectItem] = useState();
  const [infoModal, setInfoModal] = useState(false);

  const proHeight = document.body.clientHeight;
  const businessState = useSelector((state) => state.businessState.value);
  useEffect(() => {
    getItems(1);
  }, [businessState?.id]);

  const buttons = (record) => (
    <Space size="middle">
      <Tooltip title={"Change status"}>
        <div
          onClick={(event) => {
            event.stopPropagation();
            setStatusModal(true);
            setChangeStatus(record);
          }}
          className="text-blue-500 hover:text-blue-600 cursor-pointer"
        >
          <IssuesCloseOutlined />
        </div>
      </Tooltip>
      <Tooltip title={"Change position"}>
        <div
          onClick={(event) => {
            event.stopPropagation();
            setPositionModal(true);
            setChangePosition(record);
          }}
          className="text-blue-500 hover:text-blue-600 cursor-pointer"
        >
          <ColumnHeightOutlined />
        </div>
      </Tooltip>
      <Tooltip title={"More"}>
        <Dropdown
          placement="bottomRight"
          menu={{
            items,
          }}
          trigger={["click"]}
          onClick={(event) => event.stopPropagation()}
        >
          <Space>
            <div className="text-gray-500 hover:text-gray-600 cursor-pointer">
              <MoreOutlined
                onClick={(event) => {
                  event.stopPropagation();
                  setSelectItem(record);
                }}
              />
            </div>
          </Space>
        </Dropdown>
      </Tooltip>
    </Space>
  );

  async function getItems(num) {
    setTableLoading(true);
    const apiResponse = await GetItemsTableApi(num);
    if (apiResponse.success) {
      if (apiResponse.data?.menuItems?.length) {
        setCanShowTable(true);
      }
      setData(apiResponse.data);
      setCanStart(true);
      setTableLoading(false);
      setPage(num);
    }
  }

  async function deleteItem() {
    const apiResponse = await DeleteItemApi(
      selectItem.id,
      selectItem?.category?.id
    );
    if (apiResponse.error) {
      openNotificationError("bottomRight", apiResponse.message);
    } else if (apiResponse.success) {
      openNotificationSuccess("bottomRight", "Item deleted");
      if (data?.menuItems?.length.toString().endsWith("1")) {
        changeTablePage(page - 1);
      } else {
        changeTablePage(page);
      }
    } else {
      openNotificationError("bottomRight", "Couldn't delete. Try again later.");
    }
    setSelectItem("");
  }

  async function getFilteredItems(values, num) {
    setTableLoading(true);
    const apiResponse = await GetFilteredItemsTableApi(
      values.name || "",
      values.category || "",
      priceRange[0] || "",
      priceRange[1] || "",
      values.status || "",
      num
    );
    if (apiResponse.success) {
      setData(apiResponse.data);
      setTableLoading(false);
      setFilterStatus(true);
      setFilterBy({
        name: values.name,
        category: values.category,
        startPrice: priceRange[0],
        endPrice: priceRange[1],
        status: values.status,
      });
      setPage(num);
    }
  }

  const handleData = (newData) => {
    setPriceRange(newData);
  };

  function changeTablePage(num) {
    if (filterStatus) {
      getFilteredItems(filterBy, num);
    } else {
      getItems(num);
    }
  }

  const columns = [
    {
      title: "Product",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <Space className="flex gap-4" size="small">
          <div className=" h-12 w-12 md:h-18 md:w-18    justify-center items-center mx-auto ">
            <img
              src={record?.imageUrl}
              alt="avatar"
              className=" w-full h-full object-cover rounded-md bg-gray-200 border-2 border-white "
            />
          </div>
          <div className="text-sm">{record?.name}</div>
        </Space>
      ),
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      render: (text, record) => <div>{record.category?.name}</div>,
    },

    {
      title: "Position",
      dataIndex: "position",
      key: "position",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (text, item) => (
        <div>
          {" "}
          {currencyFormatter(item?.price, businessState?.currency?.symbol)}
        </div>
      ),
    },
    {
      title: "No of Modifier Groups",
      dataIndex: "noOfModifierGroups",
      key: "noOfModifierGroups",
      render: (text, record) => <div>{record?.modifierGroups.length}</div>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (
        <Space size="middle">
          {record.status === STATUS.Available ? (
            <Tag color="green">Available</Tag>
          ) : record.status === STATUS.Hidden ? (
            <Tag color="red">Hide</Tag>
          ) : (
            <Tag color="orange">Out of stock</Tag>
          )}
        </Space>
      ),
    },

    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      fixed: "right",
      width: 140,
      ellipsis: true,
      render: (text, record) => (
        <Space size="middle">
          <Tooltip title={"Change status"}>
            <Button
              type="primary"
              ghost
              size="small"
              onClick={(event) => {
                event.stopPropagation();
                setStatusModal(true);
                setChangeStatus(record);
              }}
              icon={<IssuesCloseOutlined />}
            />
          </Tooltip>
          <Tooltip title={"Change position"}>
            <Button
              type="primary"
              ghost
              size="small"
              onClick={(event) => {
                event.stopPropagation();
                setPositionModal(true);
                setChangePosition(record);
              }}
              icon={<ColumnHeightOutlined />}
            />
          </Tooltip>
          <Tooltip title={"More"}>
            <Dropdown
              placement="bottomRight"
              menu={{
                items,
              }}
              trigger={["click"]}
              onClick={(event) => event.stopPropagation()}
            >
              <Space>
                <Button
                  type="primary"
                  ghost
                  size="small"
                  icon={
                    <MoreOutlined
                      onClick={() => {
                        setSelectItem(record);
                      }}
                    />
                  }
                />
              </Space>
            </Dropdown>
          </Tooltip>
        </Space>
      ),
    },
  ];
  const items = [
    {
      label: (
        <div
          onClick={(event) => {
            event.stopPropagation();
            setEditModal(true);
          }}
        >
          Edit
        </div>
      ),
      key: "0",
    },
    {
      label: (
        <div
          onClick={(event) => {
            event.stopPropagation();
            setDeleteModal(true);
          }}
        >
          Delete
        </div>
      ),
      key: "1",
    },
  ];

  function showEmptyItems() {
    return (
      <div className="flex justify-center items-center w-full h-full">
        <div className="text-center">
          <div className="text-3xl text-gray-400">
            <WarningOutlined />
          </div>
          <div className="text-xl pri-font text-gray-400">
            No items available
          </div>
          <div className="mb-3 text-gray-400">Add your first item</div>
          <Button
            type="primary"
            style={{ background: token.colorPrimary }}
            onClick={() => setAddModal(true)}
          >
            Add an item
          </Button>
        </div>
      </div>
    );
  }

  function resetFilter() {
    getItems(1);
    setFilterStatus(false);
    setPriceRange(["", ""]);
    setFilterBy();
  }

  const right = (
    <>
      {canShowTable && (
        <>
          <Tooltip title={"Reset"}>
            <Button
              disabled={!filterStatus}
              onClick={() => resetFilter()}
              className="mr-3"
            >
              <UndoOutlined />
            </Button>
          </Tooltip>
          <Tooltip title={"Filter"}>
            <Badge
              count={
                filterStatus ? (
                  <CheckCircleFilled style={{ color: "#f5222d" }} />
                ) : (
                  0
                )
              }
              offset={[-14, 2]}
            >
              <Button className="mr-3" onClick={() => setFilterModal(true)}>
                <FilterOutlined />
              </Button>
            </Badge>
          </Tooltip>
          <Tooltip placement="left" title={"Add Items"}>
            <Button
              style={{ background: token.colorPrimary }}
              shape="circle"
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => {
                setAddModal(true);
              }}
            ></Button>
          </Tooltip>
        </>
      )}
    </>
  );

  const left = (
    <>
      <div className="pri-font text-lg hidden md:block">Items</div>
    </>
  );

  return (
    <>
      <div className="my-4">
        <DashboardBreadcrumb left={left} right={right} />
        <div
          style={{
            height: proHeight - 700,
          }}
        >
          {canStart ? (
            canShowTable ? (
              <>
                <Table
                  className="hidden md:block"
                  dataSource={data?.menuItems}
                  columns={columns}
                  loading={tableLoading}
                  pagination={{ position: ["none"] }}
                  rowClassName=" cursor-pointer"
                  onRow={(record) => {
                    return {
                      onClick: () => {
                        setInfoModal(true);
                        setSelectItem(record);
                      },
                    };
                  }}
                />

                <div className={"md:hidden grid grid-cols-1 gap-2"}>
                  {data?.menuItems.map((menuItems) => (
                    <DataCard
                      key={menuItems?.id}
                      position={menuItems?.position}
                      category={menuItems?.category?.name}
                      title={menuItems?.name}
                      actions={buttons(menuItems)}
                      price={menuItems?.price}
                      count={data?.menuItems.length}
                      status={menuItems?.status}
                    />
                  ))}
                </div>

                <div className="text-end mt-4">
                  {data?.totalCount > 10 && (
                    <Pagination
                      onChange={(num) => {
                        changeTablePage(num);
                        setPage(num);
                      }}
                      current={page}
                      total={data?.totalCount || 0}
                      pageSize={10}
                    />
                  )}
                </div>
              </>
            ) : (
              showEmptyItems()
            )
          ) : (
            <MainLoader />
          )}
        </div>
        <AddItemModal
          visible={addModal}
          data={data}
          handleCancel={() => setAddModal(false)}
          handleOk={() => getItems(1)}
        />
        <ItemsFilterModal
          visible={filterModal}
          priceRange={(value) => handleData(value)}
          handleCancel={() => setFilterModal(false)}
          handleOk={(values) => getFilteredItems(values, 1)}
        />
        <EditItemModal
          visible={editModal}
          data={selectItem}
          handleCancel={() => setEditModal(false)}
          handleOk={() => getItems(1)}
        />
        <ItemInfoModal
          visible={infoModal}
          data={selectItem}
          handleCancel={() => setInfoModal(false)}
        />
        <ChangeItemStatusModal
          visible={statusModal}
          data={changeStatus}
          handleOk={() => getItems(1)}
          handleCancel={() => setStatusModal(false)}
        />
        <ChangeItemPositionModal
          visible={positionModal}
          data={changePosition}
          handleOk={() => getItems(1)}
          handleCancel={() => setPositionModal(false)}
        />
        <ConfirmationModal
          visible={deleteModal}
          handleCancel={() => {
            setDeleteModal(false);
            setSelectItem("");
          }}
          handleOk={() => {
            deleteItem();
            setDeleteModal(false);
          }}
          text="Are you sure want to delete this item?"
          title="Delete item"
        />
      </div>
    </>
  );
}

export default Items;
