import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import {
  Button,
  Pagination,
  Popover,
  Space,
  Table,
  Tooltip,
  theme,
} from "antd";
import DashboardBreadcrumb from "../../components/layout/DashboardBreadcrumb";
import MainLoader from "../../Loader/MainLoader";
import { useEffect, useState } from "react";
import DataCard from "../../components/widgets/DataCard";
import GetModifierGroupTableApi from "../../api/modifierGroups/GetModifierGroupTableApi";
import AddModifierModal from "../../components/modals/add/AddModifierModal";
import ConfirmationModal from "../../components/modals/ConfirmationModal";
import {
  openNotificationError,
  openNotificationSuccess,
} from "../../helpers/Notifications";
import DeleteModifierApi from "../../api/modifierGroups/DeleteModifierApi";
import { useSelector } from "react-redux";
import ModifierInfoModal from "../../components/modals/ModifierInfoModal";
import EditModifierGroupModal from "../../components/modals/edit/EditModifierModal";

const { useToken } = theme;

function ModifierGroups() {
  const { token } = useToken();
  const [canShowTable, setCanShowTable] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [data, setData] = useState();
  const [canStart, setCanStart] = useState(false);
  const [page, setPage] = useState(1);
  const [deleteModal, setDeleteModal] = useState(false);
  const [removeId, setRemoveId] = useState("");
  const [modifierGroup, setModifierGroup] = useState();
  const [infoModal, setInfoModal] = useState(false);
  const [editModal, setEditModal] = useState(false);

  const businessState = useSelector((state) => state.businessState.value);

  useEffect(() => {
    getModifiers(1);
  }, [businessState?.id, removeId]);

  async function getModifiers(num) {
    setTableLoading(true);
    const apiResponse = await GetModifierGroupTableApi(num);
    if (apiResponse.success) {
      if (apiResponse.data?.modifierGroups?.length) {
        setCanShowTable(true);
      }
      setData(apiResponse.data);
      setCanStart(true);
      setTableLoading(false);
      setPage(num);
    }
  }

  async function deleteModifier() {
    const apiResponse = await DeleteModifierApi(removeId);
    if (apiResponse.error) {
      openNotificationError("bottomRight", apiResponse.message);
    } else if (apiResponse.success) {
      openNotificationSuccess(
        "bottomRight",
        "Modifier was removed successfully"
      );
    } else {
      openNotificationError("bottomRight", "Couldn't remove. Try again later.");
    }
    setRemoveId("");
  }

  function changeTablePage(num) {
    getModifiers(num);
  }

  const columns = [
    {
      title: "Modifier Group Name",
      dataIndex: "modifierGroupName",
      key: "modifierGroupName",
      render: (text, record) => <div>{record.name}</div>,
    },
    {
      title: "No of Modifiers",
      dataIndex: "noOfModifiers",
      key: "noOfModifiers",
      render: (text, record) => <div>{record.noOfModifier}</div>,
    },
    {
      title: "Linked Products",
      dataIndex: "linkedProducts",
      key: "linkedProducts",
      render: (text, record) => <div>{record.linkedProducts}</div>,
    },

    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      fixed: "right",
      width: 140,
      ellipsis: true,
      render: (text, record) => (
        <Space size="middle">
          <Tooltip title={"Edit"}>
            <Button
              type="primary"
              ghost
              size="small"
              onClick={(event) => {
                event.stopPropagation();
                setModifierGroup(record);
                setEditModal(true);
              }}
              icon={<EditOutlined />}
            />
          </Tooltip>

          {!record?.linkedProducts ? (
            <Tooltip title={"Delete"}>
              <Button
                danger
                size="small"
                onClick={(event) => {
                  event.stopPropagation();
                  setRemoveId(record.id);
                  setDeleteModal(true);
                }}
                icon={<DeleteOutlined />}
              />
            </Tooltip>
          ) : (
            <Popover
              placement="left"
              content="Modifier Group has associated with items, can't delete."
              trigger="hover"
            >
              <Button
                style={{ borderColor: "gray" }}
                size="small"
                onClick={(event) => {
                  event.stopPropagation();
                }}
                icon={<DeleteOutlined className="text-gray-500" />}
              />
            </Popover>
          )}
        </Space>
      ),
    },
  ];

  function showEmptyModifierGroups() {
    return (
      <div className="flex justify-center items-center w-full h-full">
        <div className="text-center">
          <div className="text-3xl text-gray-400">
            <WarningOutlined />
          </div>
          <div className="text-xl pri-font text-gray-400">
            No modifiers available
          </div>
          <div className="mb-3 text-gray-400">Add your first modifier</div>
          <Button
            type="primary"
            style={{ background: token.colorPrimary }}
            onClick={() => setAddModal(true)}
          >
            Add a modifier
          </Button>
        </div>
      </div>
    );
  }

  const right = (
    <>
      {canShowTable && (
        <>
          <Tooltip placement="left" title={"Add modifier group"}>
            <Button
              style={{ background: token.colorPrimary }}
              shape="circle"
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => setAddModal(true)}
            ></Button>
          </Tooltip>
        </>
      )}
    </>
  );

  const left = (
    <>
      <div className="pri-font text-lg hidden md:block">Modifier Groups</div>
    </>
  );

  return (
    <>
      <div className="my-4">
        <DashboardBreadcrumb left={left} right={right} />
        <div>
          {canStart ? (
            canShowTable ? (
              <>
                <div>
                  <Table
                    className="hidden md:block"
                    dataSource={data?.modifierGroups}
                    columns={columns}
                    loading={tableLoading}
                    pagination={{ position: ["none"] }}
                    rowClassName=" cursor-pointer"
                    onRow={(record) => {
                      return {
                        onClick: () => {
                          setInfoModal(true);
                          setModifierGroup(record);
                        },
                      };
                    }}
                  />
                </div>
                <div className={"md:hidden grid grid-cols-1 gap-2"}>
                  {data?.modifierGroups.map((modifier) => (
                    <DataCard
                      key={modifier?.id}
                      title={modifier?.name}
                      description={modifier?.noOfModifier}
                      count={modifier?.linkedProducts}
                      status={modifier?.status}
                    />
                  ))}
                </div>
                <div className="text-end mt-4">
                  {data?.totalCount > 10 && (
                    <Pagination
                      onChange={(num) => {
                        changeTablePage(num);
                        setPage(num);
                      }}
                      current={page}
                      total={data?.totalCount || 0}
                      pageSize={10}
                    />
                  )}
                </div>
              </>
            ) : (
              showEmptyModifierGroups()
            )
          ) : (
            <MainLoader />
          )}
        </div>
        <AddModifierModal
          visible={addModal}
          data={data}
          handleCancel={() => setAddModal(false)}
          handleOk={() => getModifiers(1)}
        />
        <ModifierInfoModal
          visible={infoModal}
          data={modifierGroup}
          handleCancel={() => setInfoModal(false)}
        />
        <EditModifierGroupModal
          visible={editModal}
          data={modifierGroup}
          handleCancel={() => setEditModal(false)}
          handleOk={() => getModifiers(1)}
        />
        <ConfirmationModal
          visible={deleteModal}
          handleCancel={() => setDeleteModal(false)}
          handleOk={() => {
            deleteModifier();
            setDeleteModal(false);
          }}
          text="Are you sure want to delete this Modifier Group?"
          title="Delete Modifier Group"
        />
      </div>
    </>
  );
}

export default ModifierGroups;
